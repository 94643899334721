const DATE_LOCALE = 'en-GB';
var BUTTON_VARIANT;
(function (BUTTON_VARIANT) {
    BUTTON_VARIANT[BUTTON_VARIANT["PRIMARY1"] = 1] = "PRIMARY1";
    BUTTON_VARIANT[BUTTON_VARIANT["PRIMARY2"] = 2] = "PRIMARY2";
    BUTTON_VARIANT[BUTTON_VARIANT["TERTIARY"] = 3] = "TERTIARY";
    BUTTON_VARIANT[BUTTON_VARIANT["SECONDARY"] = 4] = "SECONDARY";
})(BUTTON_VARIANT || (BUTTON_VARIANT = {}));
var BUTTON_DIRECTION;
(function (BUTTON_DIRECTION) {
    BUTTON_DIRECTION[BUTTON_DIRECTION["RIGHT"] = 1] = "RIGHT";
    BUTTON_DIRECTION[BUTTON_DIRECTION["LEFT"] = 2] = "LEFT";
    BUTTON_DIRECTION[BUTTON_DIRECTION["TOP"] = 3] = "TOP";
    BUTTON_DIRECTION[BUTTON_DIRECTION["BOTTOM"] = 4] = "BOTTOM";
})(BUTTON_DIRECTION || (BUTTON_DIRECTION = {}));
var SCREENS;
(function (SCREENS) {
    SCREENS[SCREENS["CALENDAR_PREVIEW"] = 0] = "CALENDAR_PREVIEW";
    SCREENS[SCREENS["DATE_SELECTION"] = 1] = "DATE_SELECTION";
    SCREENS[SCREENS["SLOT_SELECTION"] = 2] = "SLOT_SELECTION";
    SCREENS[SCREENS["BOOKING_FORM"] = 3] = "BOOKING_FORM";
    SCREENS[SCREENS["SUCCESS_APPOINTMENT"] = 4] = "SUCCESS_APPOINTMENT";
    SCREENS[SCREENS["SLOT_NOT_AVAILABLE"] = 5] = "SLOT_NOT_AVAILABLE";
    SCREENS[SCREENS["CAPTCHA_ERROR"] = 6] = "CAPTCHA_ERROR";
})(SCREENS || (SCREENS = {}));

export { BUTTON_DIRECTION, BUTTON_VARIANT, DATE_LOCALE, SCREENS };
