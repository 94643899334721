import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import Dropdown from './Dropdown.esm.js';
import { DOBContainer } from './styles.esm.js';
import { formatDate } from '../Calendar/utils.esm.js';

const dayItems = [
    { label: 'Day', value: null },
    ...new Array(31)
        .fill(null)
        .map((_, index) => String(index + 1))
        .map((number) => ({ label: number, value: number })),
];
const monthItems = [
    { label: 'Month', value: null },
    { label: 'January', value: '0' },
    { label: 'February', value: '1' },
    { label: 'March', value: '2' },
    { label: 'April', value: '3' },
    { label: 'May', value: '4' },
    { label: 'June', value: '5' },
    { label: 'July', value: '6' },
    { label: 'August', value: '7' },
    { label: 'September', value: '8' },
    { label: 'October', value: '9' },
    { label: 'November', value: '10' },
    { label: 'December', value: '11' },
];
const currentYear = new Date().getFullYear();
const startYear = currentYear - 18;
const yearItems = [
    { label: 'Year', value: null },
    ...Array.from({ length: 100 }, (_, index) => {
        const year = startYear - index;
        return { label: String(year), value: String(year) };
    }),
];
const DEFAULT_DATE_STATE = {
    day: null,
    month: null,
    year: null,
};
const DOBInput = ({ onChange, validate, disabled, initialValue, }) => {
    const parseDate = (date) => {
        const [year, month, day] = date.split('-').map(Number);
        return { year: String(year), month: String(month - 1), day: String(day) };
    };
    const containerRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(initialValue ? parseDate(initialValue) : DEFAULT_DATE_STATE);
    const dayItemsNormalized = useMemo(() => {
        if (selectedDate.month && selectedDate.year) {
            const maxDayNumber = new Date(+selectedDate.year, +selectedDate.month + 1, 0).getDate();
            return dayItems.filter((f) => f.value === null || +f.value <= maxDayNumber);
        }
        return dayItems;
    }, [selectedDate.month, selectedDate.year]);
    const selectedDay = useMemo(() => dayItems.find((f) => f.value === selectedDate.day), [selectedDate.day]);
    const selectedMonth = useMemo(() => monthItems.find((f) => f.value === selectedDate.month), [selectedDate.month]);
    const selectedYear = useMemo(() => yearItems.find((f) => f.value === selectedDate.year), [selectedDate.year]);
    const handleDayChange = useCallback((item) => {
        setSelectedDate((prev) => (Object.assign(Object.assign({}, prev), { day: item.value })));
    }, []);
    const handleMonthChange = useCallback((item) => {
        setSelectedDate((prev) => (Object.assign(Object.assign({}, prev), { month: item.value })));
    }, []);
    const handleYearChange = useCallback((item) => {
        setSelectedDate((prev) => (Object.assign(Object.assign({}, prev), { year: item.value })));
    }, []);
    useEffect(() => {
        if (selectedDate.day && selectedDate.month && selectedDate.year) {
            const date = new Date(+selectedDate.year, +selectedDate.month, +selectedDate.day);
            if (date.getDate() !== +selectedDate.day) {
                onChange === null || onChange === void 0 ? void 0 : onChange(null);
                setSelectedDate((prev) => (Object.assign(Object.assign({}, prev), { day: DEFAULT_DATE_STATE.day })));
                validate === null || validate === void 0 ? void 0 : validate(null);
                return;
            }
            onChange === null || onChange === void 0 ? void 0 : onChange(formatDate(date));
        }
    }, [
        selectedDate.day,
        selectedDate.month,
        selectedDate.year,
        onChange,
        validate,
    ]);
    return (React.createElement(DOBContainer, { ref: containerRef },
        React.createElement(Dropdown, { disabled: disabled, selected: selectedDay, items: dayItemsNormalized, onChange: handleDayChange }),
        React.createElement(Dropdown, { disabled: disabled, selected: selectedMonth, items: monthItems, onChange: handleMonthChange }),
        React.createElement(Dropdown, { disabled: disabled, selected: selectedYear, items: yearItems, onChange: handleYearChange })));
};

export { DOBInput as default };
