import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import FormItem from '../../components/FormItem/index.esm.js';
import Dropdown from '../../components/FormInput/Dropdown.esm.js';
import TextInput from '../../components/FormInput/TextInput.esm.js';
import DOBInput from '../../components/FormInput/DOBInput.esm.js';
import PhoneInput from '../../components/FormInput/PhoneInput.esm.js';
import FilterToggle from '../../components/FilterToggle/index.esm.js';
import { Grid, Hidden, Typography, Button, CircularProgress, GoogleRecaptchaLogo } from '@hermes/web-components';
import InsuranceDropdown from '../../components/InsuranceDropdown/index.esm.js';
import { Container, FormSection, HeaderWrapper, Header, BottomSection, CommonText, ProfileInfoSection, ProfileInfo, ProfileMetrics, ReviewsInfo, BookingInfo as BookingInfo$1 } from './styles.esm.js';
import { Title } from '../DateSelection/styles.esm.js';
import Checkbox from '../../components/Checkbox/index.esm.js';
import useFormData from '../../hooks/useFormData.esm.js';
import Star from '../../icons/Star.esm.js';
import ArrowBack from '../../icons/ArrowBack.esm.js';
import { RatingWrapper } from '../../components/LocationDropdown/styles.esm.js';
import BookingInfo from '../../views/BookingInfo/index.esm.js';
import { Container as Container$1 } from '../../views/StepFooter/styles.esm.js';
import { formatDateStringToDisplay, formatTimeStringToDisplay } from '../../components/Calendar/utils.esm.js';
import resolveThemeComponent from '../../utils/resolveThemeComponent.esm.js';
import '../../constants.esm.js';
import PreviousButton from '../../components/Button/PreviousButton.esm.js';
import { useWpaTenant } from '../../hooks/useWpaTenant.esm.js';
import { isValidPhoneNumber } from '../../node_modules/react-phone-number-input/min/index.esm.js';

const DISABLED_AUTO_SAVE_KEYS = ['agreement'];
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const CACHED_FORM_DATA_KEY_PREFIX = 'REQUEST_BOOKING_FORM_DATA';
const DEFAULT_FORM_STATE = {
    visitType: 'new',
    visitReason: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: '',
    paymentMethod: 'Select',
    bookingFor: '',
    agreement: false,
    gender: '',
    addressLine: '',
    city: '',
    postalCode: '',
    claimNumber: '',
    policyNumber: '',
    employeeId: '',
};
const BookingForm = ({ onFormSubmit, onBackButtonClick, visitTypeOptions, visitReasonOptions, bookForOptions, bookingInfo, selectedInsurance, onInsuranceSelectClick, formFields = Object.keys(DEFAULT_FORM_STATE), genderOptions, source, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { isWPA } = useWpaTenant();
    const CACHED_FORM_DATA_KEY = `${CACHED_FORM_DATA_KEY_PREFIX}_${bookingInfo.specialistProfile.id}`;
    const getInitialFormData = () => {
        const cachedData = localStorage.getItem(CACHED_FORM_DATA_KEY);
        return cachedData ? JSON.parse(cachedData) : DEFAULT_FORM_STATE;
    };
    const [formData, setFormData] = useState(getInitialFormData());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const updateFormData = useCallback((key, value) => {
        setFormData((prevData) => {
            const newData = Object.assign(Object.assign({}, prevData), { [key]: value });
            if (!DISABLED_AUTO_SAVE_KEYS.includes(key)) {
                const dataToSave = Object.assign({}, newData);
                DISABLED_AUTO_SAVE_KEYS.forEach((excludedKey) => delete dataToSave[excludedKey]);
                localStorage.setItem(CACHED_FORM_DATA_KEY, JSON.stringify(dataToSave));
            }
            return newData;
        });
    }, []);
    const [formSchema, errorMessages, closeErrorMessage] = useFormData(useMemo(() => {
        const finalSchema = {};
        const schema = {
            visitType: {
                required: true,
                type: 'invisible',
                items: visitTypeOptions,
                onChange: (item) => updateFormData('visitType', item.value),
            },
            visitReason: {
                required: false,
                type: 'dropdown',
                label: 'Reason for visit',
                items: visitReasonOptions,
                onChange: (item) => updateFormData('visitReason', item.value),
            },
            firstName: {
                required: true,
                type: 'text',
                label: 'First name',
                placeholder: 'Add first name',
                validateFn: (input) => input ? '' : 'Please enter this field',
                onChange: (value) => updateFormData('firstName', value),
            },
            lastName: {
                required: true,
                type: 'text',
                label: 'Last name',
                placeholder: 'Add last name',
                validateFn: (input) => input ? '' : 'Please enter this field',
                onChange: (value) => updateFormData('lastName', value),
            },
            email: {
                required: true,
                type: 'text',
                label: 'Email',
                placeholder: 'Add email address',
                validateFn: (input) => {
                    if (!input) {
                        return 'Please enter this field';
                    }
                    if (!emailRegex.test(input)) {
                        return 'Invalid email address';
                    }
                    return '';
                },
                onChange: (value) => updateFormData('email', value),
            },
            addressLine: {
                required: true,
                type: 'text',
                label: 'Address',
                placeholder: 'Add address',
                validateFn: (input) => input ? '' : 'Please enter this field',
                onChange: (value) => updateFormData('addressLine', value),
            },
            city: {
                required: true,
                type: 'text',
                label: 'City',
                placeholder: 'Eg London',
                validateFn: (input) => input ? '' : 'Please enter this field',
                onChange: (value) => updateFormData('city', value),
            },
            postalCode: {
                required: true,
                type: 'text',
                label: 'Post code',
                placeholder: 'Add post code',
                validateFn: (input) => input ? '' : 'Please enter this field',
                onChange: (value) => updateFormData('postalCode', value),
            },
            claimNumber: {
                required: true,
                type: 'text',
                label: 'Claim reference',
                placeholder: 'E.g. ABCDEF',
                hint: 'This is used to match your claim to your appointment',
                validateFn: (input) => {
                    if (!input || !/^[A-Za-z]{6}$/.test(input)) {
                        return 'Your WPA claim reference is a 6 letter code';
                    }
                    return '';
                },
                onChange: (value) => updateFormData('claimNumber', value),
            },
            policyNumber: {
                required: true,
                type: 'text',
                label: 'Customer number',
                placeholder: 'E.g. 123456789',
                hint: 'This is used to identify your policy',
                validateFn: (input) => {
                    if (isWPA) {
                        if (!input || !/^[0-9]{1,9}$/.test(input)) {
                            return 'Your WPA customer number is a numerical code up to 9 numbers';
                        }
                        return '';
                    }
                    if (!input) {
                        return 'Customer number is missing';
                    }
                    return '';
                },
                onChange: (value) => updateFormData('policyNumber', value),
            },
            employeeId: {
                required: false,
                type: 'text',
                label: 'Agent ID (for internal purposes only)',
                placeholder: 'E.g. ABC',
                hint: 'This optional field should only be filled in by WPA agents',
                validateFn: (input) => {
                    if ((input === null || input === void 0 ? void 0 : input.length) && !/^[A-Za-z]{3}$/.test(input)) {
                        return 'Agent ID is a 3 letter reference';
                    }
                    return '';
                },
                onChange: (value) => updateFormData('employeeId', value),
            },
            phoneNumber: {
                required: true,
                type: 'phone',
                label: 'Phone',
                placeholder: 'Add phone number',
                validateFn: (phoneNumber) => {
                    return isValidPhoneNumber(phoneNumber)
                        ? ''
                        : 'Invalid phone number';
                },
                onChange: (value) => updateFormData('phoneNumber', value),
            },
            dob: {
                required: true,
                type: 'dob',
                label: 'Date of birth',
                validateFn: (date) => {
                    if (!date) {
                        return 'Invalid input';
                    }
                    const today = new Date();
                    const birthdate = new Date(date);
                    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
                    if (birthdate > eighteenYearsAgo) {
                        return 'Must be at least 18 years old to proceed';
                    }
                    return '';
                },
                onChange: (value) => updateFormData('dob', value),
            },
            gender: {
                required: true,
                type: 'dropdown',
                items: genderOptions,
                label: 'Gender',
                validateFn: (item) => (item ? '' : 'Invalid input'),
                onChange: (item) => updateFormData('gender', item.value),
            },
            paymentMethod: {
                required: true,
                type: 'insuranceDropdown',
                label: 'Select payment method',
                validateFn: () => (selectedInsurance === null || selectedInsurance === void 0 ? void 0 : selectedInsurance.value) ? '' : 'This input is required',
                onClick: onInsuranceSelectClick,
            },
            bookingFor: {
                required: true,
                type: 'dropdown',
                label: 'Who are you booking for?',
                items: bookForOptions,
                validateFn: (value) => value ? '' : 'This input is required',
                onChange: (item) => updateFormData('bookingFor', item.value),
            },
            agreement: {
                required: true,
                type: 'checkbox',
                label: (React.createElement("span", null,
                    "I confirm I am over 18 years old and agree to the",
                    ' ',
                    React.createElement("a", { href: 'https://www.doctify.com/uk/about/privacy-policy', target: '_blank' }, "Privacy Policy"),
                    ' ',
                    "and",
                    ' ',
                    React.createElement("a", { href: 'https://www.doctify.com/uk/about/terms-and-conditions', target: '_blank' }, "Terms & Conditions"))),
                validateFn: (value) => value ? '' : 'This input is required',
                onChange: (value) => updateFormData('agreement', value),
            },
        };
        formFields.forEach((key) => {
            const formFieldKey = key;
            finalSchema[formFieldKey] = schema[formFieldKey];
        });
        return finalSchema;
    }, [
        selectedInsurance,
        visitTypeOptions,
        visitReasonOptions,
        bookForOptions,
        onInsuranceSelectClick,
        formFields,
        isWPA,
    ]));
    const onSubmit = useCallback(() => {
        var _a;
        setIsSubmitting(true);
        const filteredFormData = Object.keys(formData).reduce((data, key) => formFields.includes(key)
            ? Object.assign(Object.assign({}, data), { [key]: formData[key] }) : data, {});
        Object.keys(formSchema).forEach((key) => {
            var _a, _b;
            const formFieldKey = key;
            (_b = (_a = formSchema === null || formSchema === void 0 ? void 0 : formSchema[formFieldKey]) === null || _a === void 0 ? void 0 : _a.validate) === null || _b === void 0 ? void 0 : _b.call(_a, filteredFormData === null || filteredFormData === void 0 ? void 0 : filteredFormData[formFieldKey]);
        });
        const finalFormData = Object.assign(Object.assign({}, filteredFormData), { provider: bookingInfo.provider, availabilityId: bookingInfo.availabilityId, prefix: (_a = genderOptions.find((option) => option.value === filteredFormData.gender)) === null || _a === void 0 ? void 0 : _a.prefix, source, practiceId: bookingInfo.practiceProfile.id });
        setTimeout(() => {
            const errorItems = document.querySelectorAll('.form-input-error');
            const errors = Array.from(errorItems);
            const isFormValid = !errors.length;
            if (isFormValid) {
                onFormSubmit(finalFormData)
                    .then((isSuccessful) => {
                    if (isSuccessful) {
                        localStorage.removeItem(CACHED_FORM_DATA_KEY);
                    }
                })
                    .finally(() => {
                    setIsSubmitting(false);
                });
            }
            else {
                setIsSubmitting(false);
            }
        }, 200);
    }, [formSchema, formData]);
    const containerRef = useRef(null);
    const bookingInfoBigRef = useRef(null);
    const bookingInfoSmallRef = useRef(null);
    useEffect(() => {
        const swapElements = () => {
            if (containerRef.current &&
                bookingInfoBigRef.current &&
                bookingInfoSmallRef) {
                const container = containerRef.current;
                const bookingInfoBig = bookingInfoBigRef.current;
                const bookingInfoSmall = bookingInfoSmallRef.current;
                if (container.getBoundingClientRect().width < 1040) {
                    bookingInfoBig.style.display = 'none';
                    bookingInfoSmall.style.display = 'flex';
                }
                else {
                    bookingInfoBig.style.display = 'flex';
                    bookingInfoSmall.style.display = 'none';
                }
            }
        };
        window.addEventListener('resize', swapElements);
        swapElements();
        return () => {
            window.removeEventListener('resize', swapElements);
        };
    }, [containerRef, bookingInfoBigRef, bookingInfoSmallRef]);
    const getInputComponent = useCallback((formSchemaItem, formFieldKey) => {
        var _a;
        if (formSchemaItem.type === 'dropdown') {
            return (React.createElement(Dropdown, { selected: (_a = formSchemaItem.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.value === formData[formFieldKey]), items: formSchemaItem.items, onChange: formSchemaItem.onChange }));
        }
        if (formSchemaItem.type === 'text') {
            return (React.createElement(TextInput, { defaultValue: formData[formFieldKey], placeholder: formSchemaItem.placeholder, validate: formSchemaItem.validate, onInput: formSchemaItem.onChange }));
        }
        if (formSchemaItem.type === 'phone') {
            return (React.createElement(PhoneInput, { initialPhoneNumber: formData[formFieldKey], placeholder: formSchemaItem.placeholder, validate: formSchemaItem.validate, onInput: formSchemaItem.onChange }));
        }
        if (formSchemaItem.type === 'dob') {
            return (React.createElement(DOBInput, { initialValue: formData[formFieldKey], onChange: formSchemaItem.onChange, validate: formSchemaItem.validate }));
        }
        if (formSchemaItem.type === 'insuranceDropdown') {
            return (React.createElement(InsuranceDropdown, { selected: selectedInsurance, validate: formSchemaItem.validate, onClick: formSchemaItem.onClick }));
        }
        if (formSchemaItem.type === 'checkbox') {
            return (React.createElement(Checkbox, { label: formSchemaItem.label, onChange: formSchemaItem.onChange }));
        }
        return React.createElement(React.Fragment, null);
    }, [selectedInsurance]);
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(FormSection, { style: { paddingBottom: 20 } },
            React.createElement(HeaderWrapper, null,
                React.createElement(Header, null,
                    React.createElement(Title, { style: {
                            position: 'relative',
                        } }, resolveThemeComponent({
                        default: (React.createElement(React.Fragment, null,
                            React.createElement("div", { style: { cursor: 'pointer' }, onClick: onBackButtonClick },
                                React.createElement(ArrowBack, null)),
                            React.createElement("span", null, "Request booking"))),
                        wpa: (React.createElement(Grid, { container: true, direction: "column" },
                            React.createElement(PreviousButton, { onClick: onBackButtonClick, sx: {
                                    position: 'absolute',
                                    left: '-80px',
                                    '@media(max-width: 1240px)': {
                                        display: 'none',
                                    },
                                } }),
                            React.createElement("span", null, "Request booking"),
                            React.createElement(Hidden, { mdDown: true, implementation: "css" },
                                React.createElement(Typography, { variant: "subtitle1", component: "span", color: "text.black700" },
                                    "Please add your details to request the appointment with",
                                    ' ',
                                    bookingInfo.specialistProfile.title,
                                    ' ',
                                    bookingInfo.specialistProfile.name,
                                    "\u2019s clinic.")))),
                    })),
                    React.createElement(Container$1, { style: { margin: '8px 0px' }, ref: bookingInfoSmallRef },
                        React.createElement(BookingInfo, { specialistProfile: bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.specialistProfile, practiceProfile: bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile, dateString: formatDateStringToDisplay(bookingInfo.dateString), timeString: formatTimeStringToDisplay(bookingInfo.timeString) }))),
                (formSchema === null || formSchema === void 0 ? void 0 : formSchema.visitType) && (React.createElement(FilterToggle, { items: formSchema.visitType.items, value: formData.visitType, onChange: formSchema.visitType.onChange }))),
            Object.keys(formSchema).map((key) => {
                const formFieldKey = key;
                const formSchemaItem = formSchema[formFieldKey];
                if (formSchemaItem.type === 'invisible') {
                    return null;
                }
                return (React.createElement(FormItem, { key: key, formFieldKey: formFieldKey, label: formSchemaItem.type === 'checkbox' ? '' : formSchemaItem.label, errorMessage: errorMessages[formFieldKey], onErrorClose: closeErrorMessage, inputComponent: getInputComponent(formSchemaItem, formFieldKey), required: formSchemaItem.required, hint: formSchemaItem.hint }));
            }),
            React.createElement(BottomSection, { style: { marginTop: 12 } },
                React.createElement(Button, { disabled: isSubmitting, variant: "request", onClick: onSubmit },
                    React.createElement(Typography, { component: "span" }, "Request booking"),
                    isSubmitting && (React.createElement(CircularProgress, { size: 20, sx: { color: '#80858D' } }))),
                !isWPA && React.createElement(CommonText, null, "You won\u2019t be charged yet"),
                React.createElement(GoogleRecaptchaLogo, null))),
        React.createElement(ProfileInfoSection, { ref: bookingInfoBigRef },
            React.createElement(ProfileInfo, null,
                React.createElement("img", { alt: '', src: (_a = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.specialistProfile) === null || _a === void 0 ? void 0 : _a.imageSrc }),
                React.createElement(ProfileMetrics, null,
                    React.createElement("h5", null, (_b = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.specialistProfile) === null || _b === void 0 ? void 0 :
                        _b.title,
                        ' ', (_c = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.specialistProfile) === null || _c === void 0 ? void 0 :
                        _c.name),
                    React.createElement("h6", null, (_d = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.specialistProfile) === null || _d === void 0 ? void 0 : _d.specialty),
                    React.createElement(ReviewsInfo, null,
                        React.createElement(RatingWrapper, null,
                            React.createElement(Star, null), (_f = (_e = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.specialistProfile) === null || _e === void 0 ? void 0 : _e.rating) !== null && _f !== void 0 ? _f : 0,
                            " Trust Score"),
                        React.createElement("span", null, "\u2022"),
                        React.createElement("span", null, (_g = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.specialistProfile) === null || _g === void 0 ? void 0 :
                            _g.reviewsNumber,
                            " reviews")))),
            React.createElement("hr", null),
            React.createElement("span", null, "Your booking request"),
            React.createElement(BookingInfo$1, { style: { marginTop: '8px' } },
                React.createElement("span", null, (_h = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile) === null || _h === void 0 ? void 0 : _h.name),
                React.createElement("span", null, "\u2022"),
                React.createElement("span", { style: { whiteSpace: 'nowrap' } },
                    formatDateStringToDisplay(bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.dateString),
                    ' ',
                    formatTimeStringToDisplay(bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.timeString))))));
};

export { BookingForm as default };
