import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import DateItem from '../DateItem/index.esm.js';
import { Cell, Container, ExpandedCalendarContainer, ExpandedCalendarSide, CalendarNavigationContainer, PreviousMonthButton, CalendarContent, NextMonthButton } from './styles.esm.js';
import { formatDate, getWithTimezoneOffset, generateDaysForMonth } from './utils.esm.js';
import Caret from '../../icons/Caret.esm.js';
import '../../icons/ArrowBack.esm.js';

const EXPAND_CALENDAR_WIDTH = 955;
const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const formatDateYear = (date) => date.toLocaleString('en', { month: 'short', year: 'numeric' });
const Calendar = ({ selectableDates = [], onDateSelect, isExpanded, }) => {
    const firstAvailableDate = useMemo(() => {
        const currentDate = new Date();
        const firstAvailableDate = selectableDates.find((slotDate) => {
            const date = new Date(slotDate);
            return date >= currentDate;
        });
        return firstAvailableDate ? new Date(firstAvailableDate) : currentDate;
    }, [selectableDates]);
    const [activeDate, setActiveDate] = useState(firstAvailableDate);
    const [canExpand, setCanExpand] = useState(false);
    const activeDateNextMonth = useMemo(() => new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1), [activeDate]);
    const handlePrevMonth = useCallback(() => {
        setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1));
    }, [activeDate]);
    const handleNextMonth = useCallback(() => {
        setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1));
    }, [activeDate]);
    const handleDateSelect = useCallback((date) => {
        onDateSelect === null || onDateSelect === void 0 ? void 0 : onDateSelect(formatDate(getWithTimezoneOffset(date)));
    }, [onDateSelect]);
    const currentMonthDays = useMemo(() => {
        const currentMonthYear = activeDate.getFullYear();
        const currentMonth = activeDate.getMonth();
        return generateDaysForMonth(currentMonthYear, currentMonth);
    }, [activeDate]);
    const nextMonthDays = useMemo(() => {
        const nextMonthYear = activeDateNextMonth.getFullYear();
        const nextMonth = activeDateNextMonth.getMonth();
        return generateDaysForMonth(nextMonthYear, nextMonth);
    }, [activeDateNextMonth]);
    const containerRef = useRef(null);
    useEffect(() => {
        const container = containerRef.current;
        const handleResize = (entries) => {
            for (let entry of entries) {
                setCanExpand(entry.contentRect.width >= EXPAND_CALENDAR_WIDTH);
            }
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (container) {
            resizeObserver.observe(container);
        }
        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }
        };
    }, []);
    const renderCalendarContent = useCallback((monthDays) => (React.createElement(React.Fragment, null,
        dayNames.map((dayName) => (React.createElement(Cell, { key: dayName }, dayName))),
        monthDays.map((day) => (React.createElement(Cell, { key: formatDate(day.date) }, day.isCurrentMonth ? (React.createElement(DateItem, { sx: isExpanded ? { width: 40, height: 40 } : {}, value: String(day.date.getDate()), firstAvailable: (selectableDates === null || selectableDates === void 0 ? void 0 : selectableDates[0]) === formatDate(day.date), selected: (selectableDates === null || selectableDates === void 0 ? void 0 : selectableDates[0]) === formatDate(day.date), disabled: !selectableDates.find((f) => f === formatDate(day.date)), onClick: () => handleDateSelect(day.date) })) : null))))), [selectableDates, isExpanded]);
    return (React.createElement(Container, { ref: containerRef }, isExpanded && canExpand ? (React.createElement(ExpandedCalendarContainer, null,
        React.createElement(ExpandedCalendarSide, null,
            React.createElement(CalendarNavigationContainer, null,
                React.createElement(PreviousMonthButton, { disabled: activeDate.getMonth() === firstAvailableDate.getMonth() &&
                        activeDate.getFullYear() === firstAvailableDate.getFullYear(), onClick: handlePrevMonth },
                    React.createElement(Caret, null)),
                React.createElement("span", null, formatDateYear(activeDate)),
                React.createElement("div", null)),
            React.createElement(CalendarContent, null, renderCalendarContent(currentMonthDays))),
        React.createElement(ExpandedCalendarSide, null,
            React.createElement(CalendarNavigationContainer, null,
                React.createElement("div", null),
                React.createElement("span", null, formatDateYear(activeDateNextMonth)),
                React.createElement(NextMonthButton, { onClick: handleNextMonth },
                    React.createElement(Caret, null))),
            React.createElement(CalendarContent, null, renderCalendarContent(nextMonthDays))))) : (React.createElement(React.Fragment, null,
        React.createElement(CalendarNavigationContainer, null,
            React.createElement(PreviousMonthButton, { disabled: activeDate.getMonth() === firstAvailableDate.getMonth(), onClick: handlePrevMonth },
                React.createElement(Caret, null)),
            React.createElement("span", null, formatDateYear(activeDate)),
            React.createElement(NextMonthButton, { onClick: handleNextMonth },
                React.createElement(Caret, null))),
        React.createElement(CalendarContent, null, renderCalendarContent(currentMonthDays))))));
};

export { Calendar as default };
