import React, { useState, useCallback } from 'react';
import ReactPhoneInput from '../../node_modules/react-phone-number-input/min/index.esm.js';
import { PhoneInputContainer } from './styles.esm.js';

const defaultCountryMeta = {
    countryCode: '+44',
    countryShortName: 'GB',
};
const PhoneInput = ({ initialPhoneNumber, placeholder, required, validate, onInput, }) => {
    const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || '');
    const handleInput = useCallback((value) => {
        const input = value !== null && value !== void 0 ? value : '';
        setPhoneNumber(input);
        onInput === null || onInput === void 0 ? void 0 : onInput(input);
    }, [onInput]);
    const handleBlur = useCallback(() => {
        validate === null || validate === void 0 ? void 0 : validate(required && !phoneNumber ? '' : phoneNumber);
    }, [phoneNumber, required, validate]);
    return (React.createElement(PhoneInputContainer, null,
        React.createElement(ReactPhoneInput, { international: true, countryCallingCodeEditable: false, addInternationalOption: false, onBlur: handleBlur, placeholder: placeholder, value: phoneNumber, onChange: handleInput, defaultCountry: defaultCountryMeta.countryShortName })));
};

export { PhoneInput as default };
