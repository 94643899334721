import { styledComponent } from '@hermes/web-components';
export { CalendarContent, Cell } from '../../components/Calendar/styles.esm.js';
export { DateTimeSection, TextItem, TimesContent, TimesWrapper } from '../../components/SlotSelection/styles.esm.js';

const ContentWrapper = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '600px',
});
const ButtonsPanel = styledComponent('div')(({ theme }) => (Object.assign({ display: 'flex', gap: '8px' }, theme.resolveThemeStyles({
    wpa: {
        flexDirection: 'row-reverse',
    },
}))));
const MobileFixedContactRequestPanel = styledComponent('div')(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    zIndex: 999,
    gap: '40px',
    backgroundColor: theme.palette.background.light3,
    padding: '15px',
    borderTop: `solid thin ${theme.palette.grey[600]}`,
    borderBottom: `solid thin ${theme.palette.grey[600]}`,
    '@media (max-width: 480px)': {
        display: 'flex',
    },
}));
const DesktopContactRequestButton = styledComponent('div')({
    display: 'flex',
    width: '100%',
    '@media (max-width: 480px)': {
        display: 'none',
    },
});

export { ButtonsPanel, ContentWrapper, DesktopContactRequestButton, MobileFixedContactRequestPanel };
