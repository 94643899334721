import React from 'react';
import { Button, Typography } from '@hermes/web-components';
import Call from '../../icons/Call.esm.js';
import '../../icons/ArrowBack.esm.js';

const BookButton = ({ disabled, label, onClick }) => (React.createElement(Button, { variant: "primary2", onClick: onClick, disabled: disabled },
    React.createElement(Call, null),
    React.createElement(Typography, { component: "span" }, label !== null && label !== void 0 ? label : 'Contact')));

export { BookButton as default };
