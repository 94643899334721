import React, { useMemo, useState, useCallback } from 'react';
import { FirstAvailable } from '../../components/TimeItem/styles.esm.js';
import LocationDropdown from '../../components/LocationDropdown/index.esm.js';
import DateItem from '../../components/DateItem/index.esm.js';
import { getWithTimezoneOffset, generateDaysForMonth, formatDate } from '../../components/Calendar/utils.esm.js';
import TimeItem from '../../components/TimeItem/index.esm.js';
import { Button, Typography } from '@hermes/web-components';
import Star from '../../icons/Star.esm.js';
import ArrowRight from '../../icons/ArrowRight.esm.js';
import '../../icons/ArrowBack.esm.js';
import BookButton from '../../components/Button/ContactButton.esm.js';
import { DATE_LOCALE } from '../../constants.esm.js';
import '../../_virtual/_tslib.esm.js';
import { ContentWrapper, ButtonsPanel, DesktopContactRequestButton, MobileFixedContactRequestPanel } from './styles.esm.js';
import { Title } from '../DateSelection/styles.esm.js';
import { ProfileNameText, SecondaryText } from '../../views/BookingInfo/styles.esm.js';
import { RatingWrapper } from '../../components/LocationDropdown/styles.esm.js';
import { TextItem, TimesContent, DateTimeSection, TimesWrapper } from '../../components/SlotSelection/styles.esm.js';
import { CalendarContent, Cell } from '../../components/Calendar/styles.esm.js';

const DISPLAY_DAYS_NUMBER = 5;
const DISPLAY_SLOTS_NUMBER = 3;
const PreviewCalendar = ({ practiceProfile, specialistProfile, timeSlots, onLocationChange, onDateSelect, onSlotSelect, onCalendarOpen, onSlotsOpen, onContactClick, onViewAllAvailabilityClick, displayMobileContactPanel, displayLocationDropdown, hideTitle = false, }) => {
    var _a, _b, _c;
    const activeDate = useMemo(() => { var _a; return getWithTimezoneOffset(new Date((_a = timeSlots === null || timeSlots === void 0 ? void 0 : timeSlots[0]) === null || _a === void 0 ? void 0 : _a.date)); }, [timeSlots]);
    const year = useMemo(() => activeDate.getFullYear(), [activeDate]);
    const month = useMemo(() => activeDate.getMonth(), [activeDate]);
    const days = useMemo(() => generateDaysForMonth(year, month), [year, month]);
    const selectableDates = useMemo(() => timeSlots.map((ts) => ts.date), [timeSlots]);
    const firstAvailableDate = useMemo(() => {
        var _a, _b;
        return (_b = (_a = days.find((day) => (selectableDates === null || selectableDates === void 0 ? void 0 : selectableDates[0]) === formatDate(day.date))) === null || _a === void 0 ? void 0 : _a.date) !== null && _b !== void 0 ? _b : getWithTimezoneOffset(new Date());
    }, [selectableDates, days]);
    const lastDisplayDate = useMemo(() => {
        const lastDate = new Date(firstAvailableDate);
        lastDate.setDate(lastDate.getDate() + DISPLAY_DAYS_NUMBER);
        return lastDate;
    }, [firstAvailableDate]);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedSlot, setSelectedSlot] = useState();
    const dayNames = useMemo(() => ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], []);
    const displayDays = useMemo(() => {
        const firstDayName = firstAvailableDate.toLocaleDateString(DATE_LOCALE, {
            weekday: 'short',
        });
        let index = dayNames.indexOf(firstDayName);
        const result = [];
        for (let i = 0; i < DISPLAY_DAYS_NUMBER; i++) {
            const dayName = dayNames[index];
            index = (index + 1) % dayNames.length;
            result.push(dayName);
        }
        return result;
    }, [firstAvailableDate, dayNames]);
    const slotsToDisplay = useMemo(() => {
        let displaySlots = timeSlots
            .filter((f) => f.date === formatDate(selectedDate !== null && selectedDate !== void 0 ? selectedDate : activeDate))
            .slice(0, DISPLAY_SLOTS_NUMBER);
        return displaySlots;
    }, [selectedDate, activeDate]);
    const handleDateSelect = useCallback((date) => {
        setSelectedDate(date);
        onDateSelect === null || onDateSelect === void 0 ? void 0 : onDateSelect(formatDate(date));
    }, []);
    const handleSlotSelect = useCallback((slot) => {
        setSelectedSlot(slot);
        onSlotSelect === null || onSlotSelect === void 0 ? void 0 : onSlotSelect(slot);
    }, []);
    return (React.createElement(ContentWrapper, { style: { gap: '8px' } },
        !hideTitle ? (React.createElement(Title, { style: { fontSize: 20, marginBottom: 10 } }, "Book an appointment")) : null,
        displayLocationDropdown ? (React.createElement(TextItem, null,
            React.createElement(FirstAvailable, null),
            React.createElement("span", null, "First available appointment at:"))) : null,
        React.createElement(ContentWrapper, { style: { gap: '16px' } },
            React.createElement(LocationDropdown, { disabled: !displayLocationDropdown, label: (_a = practiceProfile === null || practiceProfile === void 0 ? void 0 : practiceProfile.name) !== null && _a !== void 0 ? _a : '', rating: (_b = practiceProfile === null || practiceProfile === void 0 ? void 0 : practiceProfile.rating) !== null && _b !== void 0 ? _b : 0, reviewsNumber: (_c = practiceProfile === null || practiceProfile === void 0 ? void 0 : practiceProfile.reviewsNumber) !== null && _c !== void 0 ? _c : 0, onClick: onLocationChange }),
            React.createElement(CalendarContent, { style: { gap: '20px' }, cols: DISPLAY_DAYS_NUMBER + 1 },
                displayDays.map((dayName, index) => (React.createElement(Cell, { key: index }, dayName))),
                React.createElement("span", null),
                days.map((day, index) => day.date >= firstAvailableDate && day.date < lastDisplayDate ? (React.createElement(Cell, { key: index },
                    React.createElement(DateItem, { sx: { height: 40, width: 40 }, value: String(day.date.getDate()), firstAvailable: (selectableDates === null || selectableDates === void 0 ? void 0 : selectableDates[0]) === formatDate(day.date), selected: (selectableDates === null || selectableDates === void 0 ? void 0 : selectableDates[0]) === formatDate(day.date), disabled: !selectableDates.find((f) => f === formatDate(day.date)), onClick: () => handleDateSelect(day.date) }))) : null),
                React.createElement(DateItem, { sx: {
                        height: 40,
                        width: 40,
                        backgroundColor: 'common.white',
                        color: 'primary.main',
                    }, value: React.createElement(ArrowRight, null), onClick: onCalendarOpen })),
            React.createElement(TimesContent, null,
                React.createElement(DateTimeSection, null,
                    React.createElement(TimesWrapper, null,
                        slotsToDisplay.map((slot, index) => (React.createElement(TimeItem, { sx: { visibility: slot ? 'visible' : 'hidden' }, key: index, onClick: () => handleSlotSelect(slot), value: slot === null || slot === void 0 ? void 0 : slot.time, selected: selectedSlot && selectedSlot.id === (slot === null || slot === void 0 ? void 0 : slot.id) }))),
                        React.createElement(TimeItem, { sx: { backgroundColor: 'common.white' }, onClick: onSlotsOpen, value: 'More' })))),
            React.createElement(ButtonsPanel, null,
                React.createElement(Button, { variant: "tertiary", onClick: onViewAllAvailabilityClick },
                    React.createElement(Typography, { component: "span" }, "View all")),
                React.createElement(DesktopContactRequestButton, null,
                    React.createElement(BookButton, { onClick: onContactClick })))),
        displayMobileContactPanel ? (React.createElement(MobileFixedContactRequestPanel, null,
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(ProfileNameText, { style: { fontSize: 15 } },
                    (specialistProfile === null || specialistProfile === void 0 ? void 0 : specialistProfile.title) && (React.createElement("span", null, specialistProfile === null || specialistProfile === void 0 ? void 0 :
                        specialistProfile.title,
                        " ")),
                    React.createElement("span", null, specialistProfile === null || specialistProfile === void 0 ? void 0 : specialistProfile.name)),
                React.createElement(SecondaryText, null,
                    React.createElement(RatingWrapper, { style: { fontWeight: 600 } },
                        React.createElement(Star, null),
                        React.createElement("span", null, specialistProfile === null || specialistProfile === void 0 ? void 0 : specialistProfile.rating),
                        React.createElement("span", null, "\u2022"),
                        React.createElement("span", null, specialistProfile === null || specialistProfile === void 0 ? void 0 :
                            specialistProfile.reviewsNumber,
                            " Reviews")))),
            React.createElement("div", null,
                React.createElement(BookButton, { onClick: onContactClick })))) : null));
};

export { PreviewCalendar as default };
