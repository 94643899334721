import React from 'react';
import { Container, ContentWrapper, Link } from '../SuccessAppointment/styles.esm.js';
import { Title } from '../SlotNotAvailable/styles.esm.js';

const CaptchaError = ({ profilePageLink }) => {
    return (React.createElement(Container, null,
        React.createElement(ContentWrapper, null,
            React.createElement(Title, null, "Could not verify reCAPTCHA. Please try again."),
            React.createElement(Link, { href: profilePageLink }, "Back to profile page"))));
};

export { CaptchaError as default };
