import React from 'react';
import { styledComponent } from '@hermes/web-components';

const ArrowBackWrapper = styledComponent('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        paddingTop: '2px',
    },
}));
const ArrowBack = () => {
    return (React.createElement(ArrowBackWrapper, null,
        React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.77095 15.6634L9.77328 15.661L4.19328 10.071L16.3633 10.071C16.9156 10.071 17.3633 9.62331 17.3633 9.07102C17.3633 8.51874 16.9156 8.07102 16.3633 8.07102L4.19328 8.07102L9.77328 2.48102L9.77142 2.47916C9.96589 2.29733 10.0874 2.03847 10.0874 1.75123C10.0874 1.20094 9.64133 0.75484 9.09104 0.75484C8.80141 0.75484 8.54064 0.878416 8.35859 1.07572L0.363281 9.07102L8.329 17.0367C8.51157 17.2436 8.77868 17.374 9.07626 17.374C9.62655 17.374 10.0726 16.9279 10.0726 16.3776C10.0726 16.0975 9.95705 15.8444 9.77095 15.6634Z", fill: "currentColor" }))));
};

export { ArrowBack as default };
