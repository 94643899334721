function Button(theme) {
    return {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 24px',
                borderRadius: '20px',
                cursor: 'pointer',
                outline: 'inherit',
                border: 'none',
                fontFamily: `'Poppins', sans-serif`,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
                width: '100%',
                userSelect: 'none',
                transition: '0s !important',
                textTransform: 'none',
                '& > svg': {
                    minWidth: '20px',
                    minHeight: '20px',
                },
                '&:hover': {
                    opacity: '1 !important',
                },
                '&:disabled': {
                    backgroundColor: theme.palette.background.light3,
                    color: theme.palette.text.black600,
                    pointerEvents: 'none',
                    '& > svg': {
                        color: theme.palette.text.black600,
                    },
                },
            },
        },
        variants: [
            {
                props: { variant: 'primary1' },
                style: {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover, &:active': {
                        background: 'linear-gradient(90deg, rgba(43, 89, 224, 0.7) 50%, rgba(14, 182, 213, 0.513705) 83.26%, rgba(0, 229, 208, 0.7) 99.99%)',
                    },
                    '& > svg': {
                        color: theme.palette.common.white,
                    },
                },
            },
            {
                props: { variant: 'primary2' },
                style: {
                    padding: '8px 16px 8px 12px',
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.common.lightNavy100,
                    gap: '5px',
                    '&:hover, &:active': {
                        background: 'linear-gradient(90deg, rgba(17, 41, 113, 0.5) 0%, rgba(43, 89, 224, 0.5) 99.99%, rgba(43, 89, 224, 0.5) 100%), linear-gradient(0deg, #2B59E0, #2B59E0)',
                    },
                    '& > svg': {
                        color: theme.palette.common.white,
                    },
                },
            },
            {
                props: { variant: 'tertiary' },
                style: {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.common.white,
                    border: `1px solid ${theme.palette.primary.main}`,
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                    },
                    '&:active': {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            },
            {
                props: { variant: 'secondary' },
                style: {
                    padding: '8px',
                    border: `solid thin ${theme.palette.text.darkNavy900}`,
                    color: theme.palette.text.darkNavy900,
                    backgroundColor: 'transparent',
                    '& > svg': {
                        display: 'block',
                    },
                    '& > span': {
                        display: 'none',
                    },
                    '&:active': {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.text.darkNavy900,
                    },
                    '@media (min-width: 480px)': {
                        padding: '8px 24px',
                        '&:hover': {
                            backgroundColor: theme.palette.background.default,
                        },
                        '& > svg': {
                            display: 'none',
                        },
                        '& > span': {
                            display: 'block',
                        },
                        '&:active': {
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.text.darkNavy900,
                        },
                    },
                },
            },
            {
                props: { variant: 'request' },
                style: {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        background: 'linear-gradient(90deg, #2258B1 0%, #143977 100%)',
                    },
                },
            },
        ],
    };
}

export { Button as default };
