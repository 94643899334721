import React, { cloneElement, useCallback } from 'react';
import { Container, Label, InputWrapper, InputError, ErrorText } from './styles.esm.js';
import { Grid, WhiteTooltip, InfoOutlinedIcon } from '@hermes/web-components';
import Warn from '../../icons/Warn.esm.js';
import Cross from '../../icons/Cross.esm.js';
import '../../icons/ArrowBack.esm.js';

const FormItem = ({ label, inputComponent, disabled, errorMessage, required, onErrorClose, formFieldKey, hint, }) => {
    const inputWithProps = cloneElement(inputComponent, {
        disabled,
        isError: Boolean(errorMessage),
        required,
    });
    const handleCloseErrorMessage = useCallback(() => {
        if (onErrorClose) {
            onErrorClose(formFieldKey || '');
        }
    }, []);
    return (React.createElement(Container, null,
        label ? (React.createElement(Grid, { container: true, alignItems: "center", gap: "8px" },
            React.createElement(Label, { disabled: disabled, required: required }, label),
            !!(hint === null || hint === void 0 ? void 0 : hint.length) && (React.createElement(WhiteTooltip, { componentsProps: {
                    tooltip: {
                        sx: {
                            height: 'auto !important',
                            padding: '10px !important',
                        },
                    },
                }, title: hint },
                React.createElement(Grid, { container: true, width: "auto" },
                    React.createElement(InfoOutlinedIcon, { sx: {
                            width: '16px',
                            height: '16px',
                        } })))))) : null,
        React.createElement(InputWrapper, null, inputWithProps),
        errorMessage ? (React.createElement(InputError, { className: 'form-input-error' },
            React.createElement(ErrorText, null,
                React.createElement(Warn, null),
                React.createElement("span", null, errorMessage)),
            React.createElement(Grid, { container: true, width: "auto", onClick: handleCloseErrorMessage, sx: {
                    cursor: 'pointer',
                } },
                React.createElement(Cross, null)))) : null));
};

export { FormItem as default };
