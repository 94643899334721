import React from 'react';
import { Container, ContentWrapper, Title, LocationSection, SummarySection, Link } from './styles.esm.js';
import AirplaneWithTracer from '../../icons/AirplaneWithTracer.esm.js';
import Bell from '../../icons/Bell.esm.js';
import '../../icons/ArrowBack.esm.js';
import resolveThemeComponent from '../../utils/resolveThemeComponent.esm.js';

const SuccessAppointment = ({ location, locationContactNumber, profilePageLink, }) => {
    return (React.createElement(Container, null,
        React.createElement(ContentWrapper, null,
            resolveThemeComponent({
                default: React.createElement(AirplaneWithTracer, null),
            }),
            React.createElement("div", null,
                React.createElement(Title, null, "Your appointment request has been sent"),
                React.createElement(LocationSection, { style: { padding: '16px 0' } },
                    React.createElement("b", null, location),
                    React.createElement("div", null, "will notify you when they confirm it.")),
                React.createElement(SummarySection, null,
                    React.createElement(Bell, null),
                    React.createElement("div", null, "Estimated time: ~ 24 hours"),
                    React.createElement("div", null,
                        location,
                        ": ",
                        locationContactNumber))),
            React.createElement(Link, { href: profilePageLink }, "Back to profile page"))));
};

export { SuccessAppointment as default };
